<template>
  <v-flex xs12 sm6 :class="classes">
    <v-dialog
      v-model="dialog"
      :fullscreen="movil"
      hide-overlay
      scrollable
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on }">
        <v-card class="mx-2">
          <v-layout wrap>
            <v-card-title class="text-center subtitle-1">
              {{ titulo }}
            </v-card-title>
            <v-card-text class="pa-0">
              <span class="subtitle ma-2 font-italic">{{ fecha }}</span>
              <v-img :src="imagen" aspect-ratio="2">
                <template v-slot:placeholder>
                  <v-layout fill-height align-center justify-center ma-0>
                    <v-progress-circular
                      indeterminate
                      color="primary lighten-2"
                    ></v-progress-circular>
                  </v-layout>
                </template>
              </v-img>
            </v-card-text>
            <v-card-text class="mb-0">
              {{ texto }}
            </v-card-text>
            <v-card-actions>
              <v-btn
                text
                color="deep-purple accent-4"
                v-on="on"
                @click="traerContenido(id)"
              >
                Leer mas
              </v-btn>
            </v-card-actions>
          </v-layout>
        </v-card>
      </template>
      <v-card>
        <v-card-text class="ql-container" v-if="contenido != null">
          <div class="ql-editor" v-html="contenido"></div>
        </v-card-text>
        <v-card-text v-else>
          <v-layout column wrap text-center justify-center align-center>
            <v-flex>
              <v-img :src="$store.state.logo" width="250px"></v-img>
            </v-flex>
            <h2 class="my-5">Cargando Contenido...</h2>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="error" text @click="dialog = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-flex>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      dialog: false,
    };
  },
  props: {
    id: {
      type: String,
      default: "0",
    },
    size: {
      type: Number,
      default: 1,
    },
    titulo: {
      type: String,
      default: "Titulo de la Entrada",
    },
    texto: {
      type: String,
      default: "Texto del blog",
    },
    fecha: {
      type: String,
      default: "Fecha",
    },
    imagen: {
      type: String,
      default: "https://cdn.vuetifyjs.com/images/cards/mountain.jpg",
    },
    contenido: {
      type: String,
      default: null,
    },
  },

  methods: {
    traerContenido(id) {
      this.axios
        .get("https://www.ofimania.com/API/Blog/Get1/" + id)
        .then((response) => {
          this.contenido = response.data.contenido;
        })
        .catch((error) => {
          this.$store.state.alerta = {
            estado: true,
            tipo: "error",
            titulo: "Error de Conexion",
            info: "Verifique su Conexion a Internet" + error,
          };
        });
    },
  },

  computed: {
    ...mapState(["movil"]),
    classes() {
      return {
        md6: this.size === 2,
        md4: this.size === 3,
        md3: this.size === 4,
      };
    },
  },
};
</script>

<style>
.v-image__image {
  transition: 0.3s linear;
}

.ql-editor img {
  max-width: 80vw;
}
</style>
