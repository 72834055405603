<template>
  <v-layout wrap mb-3>
    <v-layout wrap justify-center v-if="Blog">
      <EntradaBlog
        v-for="(articulo, i) in articulosPaginados"
        :key="i"
        :size="layout[i]"
        :id="articulo.id"
        :titulo="articulo.titulo"
        :texto="articulo.texto"
        :fecha="articulo.fecha.slice('', 10)"
        :imagen="articulo.imagen"
      />
    </v-layout>
    <v-layout wrap justify-center v-if="Hazlo">
      <EntradaHTM
        v-for="(articulo, i) in articulosPaginados"
        :key="i"
        :contenido="articulo.contenido"
        :titulo="articulo.titulo"
        :video="articulo.video"
        :imagen="articulo.imagen"
      />
    </v-layout>
    <v-layout row wrap mx-3 v-if="Productos">
      <v-flex
        v-for="(articulo, index) in articulosPaginados"
        :key="index"
        xs12
        sm6
        md3
        lg2
        pa-3
      >
        <Articulo
          :id="articulo.id"
          :nombre="articulo.nombre"
          :imagen="articulo.imagen"
          :precio="articulo.precio"
          :descripcion="articulo.descripcion"
          :etiqueta="articulo.etiqueta"
        />
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-pagination
        v-model="page"
        :length="pages"
        :total-visible="7"
      ></v-pagination>
    </v-layout>
  </v-layout>
</template>

<script>
import { mapState } from "vuex";
import Articulo from "../components/Articulo.vue";
import EntradaHTM from "../components/EntradaHTM.vue";
import EntradaBlog from "../components/EntradaBlog.vue";
export default {
  components: {
    Articulo,
    EntradaHTM,
    EntradaBlog,
  },
  data: () => ({
    page: 1,
    layout: [3, 3, 3, 4, 4, 4, 4, 3, 3, 3, 4, 4, 4, 4],
  }),
  props: {
    array: {
      type: Array,
      default: () => {
        return [];
      },
    },
    slice: {
      type: Number,
      default: 5,
    },
    Blog: {
      type: Boolean,
      default: false,
    },
    Hazlo: {
      type: Boolean,
      default: false,
    },
    Productos: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    pages() {
      let slice = Math.ceil(
        this.$store.state.movil ? this.slice / 2 : this.slice
      );
      return Math.ceil(this.array.length / slice);
    },
    articulosPaginados() {
      let slice = Math.ceil(
        this.$store.state.movil ? this.slice / 2 : this.slice
      );
      let start = (this.page - 1) * slice;
      let stop = this.page * slice;
      return this.array.slice(start, stop);
    },
  },
  watch: {
    page() {
      document.getElementById("app").scrollIntoView({
        behavior: "smooth",
      });
    },
  },
};
</script>
