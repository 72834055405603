<template>
  <iframe
    width="100%"
    :height="$store.state.windowS.y * (movil ? 0.4 : 0.6)"
    :src="'https://www.youtube-nocookie.com/embed/' + link"
    frameborder="0"
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
  ></iframe>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    link: {
      type: String,
      default: "WYTbUqRV0ts",
    },
  },
  computed: {
    ...mapState(["movil"]),
  },
};
</script>
