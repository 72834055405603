<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="movil"
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on }">
      <v-flex xs6 sm4 md3 text-center>
        <v-hover v-slot:default="{ hover }">
          <v-img :aspect-ratio="1.3" cover :src="imagen">
            <v-expand-transition>
              <div
                v-if="hover"
                class="d-flex transition-fast-in-fast-out primary darken-3 v-card--reveal display-2 white--text"
                style="height: 100%;"
              >
                <v-btn
                  color="secondary"
                  min-height="100%"
                  min-width="100%"
                  text
                  dark
                  v-on="on"
                  :class="$store.state.movil ? 'caption' : 'display-1'"
                >
                  Ver Video
                </v-btn>
              </div>
            </v-expand-transition>
            <template v-slot:placeholder>
              <v-layout fill-height align-center justify-center ma-0>
                <v-progress-circular
                  indeterminate
                  color="primary lighten-2"
                ></v-progress-circular>
              </v-layout>
            </template>
          </v-img>
        </v-hover>
        <h2>{{ titulo }}</h2>
      </v-flex>
    </template>
    <v-card>
      <v-layout wrap>
        <v-flex xs12>
          <v-btn icon dark @click="dialog = false">
            <v-icon color="primary">mdi-close-circle</v-icon>
          </v-btn>
        </v-flex>
        <v-flex xs12 text-center mb-4>
          <span :class="$store.state.movil ? 'display-1' : 'display-2'">{{
            titulo
          }}</span>
        </v-flex>
        <v-flex :class="$store.state.movil ? '' : 'xs6'" mx-auto>
          <VideoYt :link="video" />
        </v-flex>
        <v-flex xs10 mx-auto mt-3>
          <span :class="$store.state.movil ? 'headline' : 'display-1'"
            >Descripcion del Video</span
          >
        </v-flex>
        <v-flex xs11 mx-auto my-4>
          <div v-html="contenido"></div>
        </v-flex>
      </v-layout>
    </v-card>
  </v-dialog>
</template>

<script>
import VideoYt from "../components/VideoYt.vue";
import { mapState } from "vuex";
export default {
  data() {
    return {
      dialog: false,
    };
  },
  props: {
    video: {
      type: String,
      default: "WYTbUqRV0ts",
    },
    titulo: {
      type: String,
      default: "Titulo del Video",
    },
    imagen: {
      type: String,
      default: "Imagen de la Entrada",
    },
    contenido: {
      type: String,
      default:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque quis in sed impedit! Quibusdam ipsum dignissimos eos provident modi facere est, optio debitis eaque cupiditate quas amet voluptatem possimus minima? Lorem, ipsum dolor sit amet consectetur adipisicing elit. Sequi, id velit, ad exercitationem quaerat sed cum incidunt aspernatur ratione fuga nihil rerum officiis itaque! Eaque sunt facere harum libero illum.",
    },
  },
  components: {
    VideoYt,
  },
  computed: {
    ...mapState(["movil"]),
  },
};
</script>

<style>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}
</style>
