<template>
  <v-dialog v-model="dialog" max-width="950">
    <template v-slot:activator="{ on }">
      <v-card
        style="border-radius: 15px;"
        class="text-center"
        max-width="500"
        height="100%"
        hover
        raised
        v-on="on"
      >
        <v-layout wrap fill-height align-content-space-between justify-center>
          <v-flex xs12>
            <v-img
              :src="'https://www.ofimania.com/img/Productos/' + id + '.webp'"
              :alt="imagen"
              :aspect-ratio="1.1"
              class="white"
              style="border-top-right-radius: 15px;border-top-left-radius: 15px;"
            >
              <v-layout
                slot="placeholder"
                fill-height
                align-center
                justify-center
                ma-0
              >
                <v-progress-circular
                  indeterminate
                  color="secondary"
                ></v-progress-circular>
              </v-layout>
            </v-img>
          </v-flex>
          <v-flex xs12>
            <v-card-title
              v-text="nombre"
              style="word-break:normal"
              class="justify-center align center"
            />
          </v-flex>
          <v-flex xs12>
            <v-card-text>
              <v-divider class="mx-5" color="primary"></v-divider>
              Precio: {{ precio }}
            </v-card-text>
          </v-flex>
        </v-layout>
      </v-card>
    </template>
    <v-card style="border-radius: 15px;" class="text-center">
      <v-layout wrap fill-height align-content-space-between>
        <v-flex lg7 order-xs1 order-lg12>
          <v-layout fill-height wrap grow justify-center>
            <v-flex xs12>
              <v-card-title class="display-1 justify-center align center">{{
                nombre
              }}</v-card-title>
              <v-divider class="mx-5" color="primary"></v-divider>
            </v-flex>
            <v-flex xs12 v-if="$store.state.movil">
              <v-img
                :src="
                  'https://www.ofimania.com/img/Productos/' + id + '.webp'
                "
                :alt="imagen"
                :aspect-ratio="1.1"
                class="white"
                style="border-top-left-radius: 15px;"
              >
                <v-layout
                  slot="placeholder"
                  fill-height
                  align-center
                  justify-center
                  ma-0
                >
                  <v-progress-circular
                    indeterminate
                    color="secondary"
                  ></v-progress-circular>
                </v-layout>
              </v-img>
            </v-flex>
            <v-flex align-center xs12>
              <v-card-text class="text-lg-h5" v-html="descripcion" />
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex lg5 order-xs12 order-lg1>
          <v-flex xs12 v-if="!$store.state.movil">
            <v-img
              :src="'https://www.ofimania.com/img/Productos/' + id + '.webp'"
              :alt="imagen"
              :aspect-ratio="1.1"
              class="white"
              style="border-top-left-radius: 15px;"
            >
              <v-layout
                slot="placeholder"
                fill-height
                align-center
                justify-center
                ma-0
              >
                <v-progress-circular
                  indeterminate
                  color="secondary"
                ></v-progress-circular>
              </v-layout>
            </v-img>
          </v-flex>
          <v-divider class="mt-3 mx-3" color="primary"></v-divider>
          <v-flex justify-center class="my-5">
            <p class="text-h6 text-bold">{{ precio }}</p>
            <v-btn
              color="darken-2 success"
              text
              :href="
                'https://api.whatsapp.com/send?phone=584149299013&text=Quisiera%20saber%20mas%20sobre%20el%20producto%20%27' +
                  nombre +
                  '%27'
              "
              target="blank"
            >
              <v-icon left size="20px">mdi-whatsapp</v-icon> Consultar
            </v-btn>
          </v-flex>
        </v-flex>
      </v-layout>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
    };
  },
  props: {
    id: { type: String, default: "-1" },
    nombre: {
      type: String,
      default: "Producto Ejemplo",
    },
    imagen: {
      type: String,
      default: "Imagen de ejemplo",
    },
    descripcion: {
      type: String,
      default: "Descripcion del Producto",
    },
    etiqueta: {
      type: String,
      default: "DETAL",
    },
    categoria: {
      type: String,
      default: "Arte",
    },
    precio: {
      type: String,
      default: "$",
    },
    home: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped></style>
